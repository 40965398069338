/**
 * @flow
 * @relayHash 6d5ec8460d1de1632f14531dcbe3eed5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Notify_data$ref = any;
export type miscNotifyQueryVariables = {||};
export type miscNotifyQueryResponse = {|
  +$fragmentRefs: Notify_data$ref
|};
export type miscNotifyQuery = {|
  variables: miscNotifyQueryVariables,
  response: miscNotifyQueryResponse,
|};
*/


/*
query miscNotifyQuery {
  ...Notify_data
}

fragment Notify_data on Query {
  ...Toolbar_data
}

fragment Toolbar_data on Query {
  me {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "miscNotifyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Notify_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "miscNotifyQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "miscNotifyQuery",
    "id": null,
    "text": "query miscNotifyQuery {\n  ...Notify_data\n}\n\nfragment Notify_data on Query {\n  ...Toolbar_data\n}\n\nfragment Toolbar_data on Query {\n  me {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
// prettier-ignore
(node/*: any*/).hash = '467f8198079c32bfb6b964404ff7d965';
module.exports = node;
