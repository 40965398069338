/**
 * @flow
 * @relayHash 70d294d48ab0137136c6fcafe01caacd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type Profile_me$ref = any;
type Profile_user$ref = any;
export type profileQueryVariables = {|
  username: string
|};
export type profileQueryResponse = {|
  +me: ?{|
    +$fragmentRefs: Profile_me$ref
  |},
  +user: ?{|
    +displayName: ?string,
    +$fragmentRefs: Profile_user$ref,
  |},
  +$fragmentRefs: Layout_data$ref,
|};
export type profileQuery = {|
  variables: profileQueryVariables,
  response: profileQueryResponse,
|};
*/


/*
query profileQuery(
  $username: String!
) {
  ...Layout_data
  me {
    ...Profile_me
    id
  }
  user(username: $username) {
    displayName
    ...Profile_user
    id
  }
}

fragment Layout_data on Query {
  me {
    ...AppBar_me
    ...AutoUpdater_me
    ...UserSettingsDialog_me
    id
  }
}

fragment Profile_me on User {
  id
}

fragment Profile_user on User {
  ...ProfileHeader_user
  id
  displayName
}

fragment ProfileHeader_user on User {
  id
  photoURL
  displayName
}

fragment AppBar_me on User {
  id
  photoURL
  displayName
}

fragment AutoUpdater_me on User {
  id
  timeZone
}

fragment UserSettingsDialog_me on User {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "username",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "photoURL",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "profileQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Profile_me",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "Profile_user",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "profileQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "profileQuery",
    "id": null,
    "text": "query profileQuery(\n  $username: String!\n) {\n  ...Layout_data\n  me {\n    ...Profile_me\n    id\n  }\n  user(username: $username) {\n    displayName\n    ...Profile_user\n    id\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...AppBar_me\n    ...AutoUpdater_me\n    ...UserSettingsDialog_me\n    id\n  }\n}\n\nfragment Profile_me on User {\n  id\n}\n\nfragment Profile_user on User {\n  ...ProfileHeader_user\n  id\n  displayName\n}\n\nfragment ProfileHeader_user on User {\n  id\n  photoURL\n  displayName\n}\n\nfragment AppBar_me on User {\n  id\n  photoURL\n  displayName\n}\n\nfragment AutoUpdater_me on User {\n  id\n  timeZone\n}\n\nfragment UserSettingsDialog_me on User {\n  id\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '59e776396b230f2376daf55cafc94cae';
module.exports = node;
