/**
 * @flow
 * @relayHash 982d8d195e6562c7018142096256f499
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_data$ref = any;
type Story_story$ref = any;
export type newsStoryQueryVariables = {|
  slug: string
|};
export type newsStoryQueryResponse = {|
  +story: ?{|
    +title: string,
    +slug: string,
    +$fragmentRefs: Story_story$ref,
  |},
  +$fragmentRefs: Layout_data$ref,
|};
export type newsStoryQuery = {|
  variables: newsStoryQueryVariables,
  response: newsStoryQueryResponse,
|};
*/


/*
query newsStoryQuery(
  $slug: String!
) {
  ...Layout_data
  story(slug: $slug) {
    ...Story_story
    title
    slug
    id
  }
}

fragment Layout_data on Query {
  me {
    ...AppBar_me
    ...AutoUpdater_me
    ...UserSettingsDialog_me
    id
  }
}

fragment Story_story on Story {
  title
  text
  isURL
}

fragment AppBar_me on User {
  id
  photoURL
  displayName
}

fragment AutoUpdater_me on User {
  id
  timeZone
}

fragment UserSettingsDialog_me on User {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "newsStoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "story",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Story",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "Story_story",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Layout_data",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "newsStoryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photoURL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "timeZone",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "story",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Story",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "text",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isURL",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "newsStoryQuery",
    "id": null,
    "text": "query newsStoryQuery(\n  $slug: String!\n) {\n  ...Layout_data\n  story(slug: $slug) {\n    ...Story_story\n    title\n    slug\n    id\n  }\n}\n\nfragment Layout_data on Query {\n  me {\n    ...AppBar_me\n    ...AutoUpdater_me\n    ...UserSettingsDialog_me\n    id\n  }\n}\n\nfragment Story_story on Story {\n  title\n  text\n  isURL\n}\n\nfragment AppBar_me on User {\n  id\n  photoURL\n  displayName\n}\n\nfragment AutoUpdater_me on User {\n  id\n  timeZone\n}\n\nfragment UserSettingsDialog_me on User {\n  id\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76c0fd119515b036f7ac17ed7be09e6e';
module.exports = node;
